import "../scss/style.scss";
import "@css/promoarh.pcss";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();

import '@js/parts/timer';
import '@js/parts/counter';

var customModal = document.querySelector("#dialog-content");

if (customModal) {
    var dataAllowClosing = customModal.getAttribute("data-allow-closing");
    var popupId = customModal.getAttribute("data-id");
    var hasPopupBeenClosed = localStorage.getItem(popupId + "-closed");
    if (!hasPopupBeenClosed) {
        Fancybox.show([{
            src: "#dialog-content", type: "inline"
        }], {
            mainClass: "custom-modal",
            on: {
                close: function () {
                    if (dataAllowClosing !== null) {
                        localStorage.setItem(popupId + "-closed", true);
                    }
                }
            }
        });
    }

}

Fancybox.bind('[data-fancybox]', {
    // Your custom options for a specific gallery
});


// Initialize the map when the page is loaded
// window.addEventListener("load", () => {
//     initMap();
// });

window.scrollToElement = function (target) {
    const element = document.getElementById(target);
    if (!element) return;

    const offset = 150;
    const offsetPosition = element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset;

    window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
    });
}